import { styled } from '@material-ui/core';
import Image from 'next/image';

interface ContainerProps {
  borderColor: string;
}

export const Container = styled('div')((props: ContainerProps) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px solid',
  borderColor: props.borderColor,
  borderRadius: '100%',
  width: '15vw',
  height: '15vw',
  maxWidth: '75px',
  maxHeight: '75px',
  flexShrink: 0,
  marginRight: '13px',
  padding: '2px',

  '&:first-child': {
    marginLeft: '20px',
  },

  '&:last-child': {
    marginRight: '20px',
  },
}));

export const Img = styled(Image)({
  borderRadius: '100%',
});
