import { memo, useMemo } from 'react';

import { Container, Img } from './styles';

export interface ICardProps {
  background?: string;
  title: string;
  onClick?(): void;
}

const CardMobile: React.FC<ICardProps> = ({ background, title, onClick }) => {
  const randomColor = useMemo(
    () =>
      // eslint-disable-next-line no-bitwise
      `#${((Math.random() * 0xffffff) << 0).toString(16)}000000`.slice(0, 7),
    [],
  );

  return (
    <Container onClick={onClick} borderColor={randomColor}>
      <Img
        width="75px"
        height="75px"
        objectFit="cover"
        quality="30"
        src={background || '/share.webp'}
        alt={title}
      />
    </Container>
  );
};

export default memo(CardMobile);
