import { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import { useTracking } from '@hooks/tracking';
import { Container, ContainerMobile, Divider } from './styles';
import CardMobile from './CardMobile';
import Skeleton from './Skeleton';

import { ListProps } from '.';

const StoriesList: React.FC<ListProps> = ({ contents, onClick, className }) => {
  const { track } = useTracking();

  const perPage = 4;
  const refContainer = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [containerWidth, setContainerWidth] = useState(0);
  const [contentsPage, setContentsPage] = useState(contents?.slice(0, 1));

  useEffect(() => {
    if (refContainer.current && contents?.length > 0) {
      const contentsPerPage =
        Math.round(containerWidth / (containerWidth * 0.15)) + 1;
      setContentsPage(contents.slice(0, contentsPerPage));
    }
  }, [contents, containerWidth]);

  const handleClientWidth = useCallback(() => {
    setContainerWidth(refContainer.current?.clientWidth);
  }, []);

  useEffect(() => {
    handleClientWidth();
    window.addEventListener('resize', handleClientWidth, {
      passive: true,
    });
    return () => {
      window.removeEventListener('resize', handleClientWidth);
    };
    // eslint-disable-next-line
  }, []);

  const handleNextPage = () => {
    if (contentsPage.length < contents.length) {
      setPage(page + 1);
      setContentsPage(contents.slice(0, (page + 1) * perPage));
    }
  };

  return (
    <ContainerMobile ref={refContainer} className={className}>
      <Container
        vertical
        horizontal
        hideScrollbars
        onScroll={() => handleNextPage()}
      >
        {contentsPage?.map((content, index) => (
          <CardMobile
            key={content.slug}
            background={content.background}
            title={content.title}
            onClick={() => {
              track('Clicked in highlights section', {
                contentTitle: content.title,
              });
              return onClick && onClick(index);
            }}
          />
        )) || <Skeleton />}
        <Box minWidth="10px" height="1px" />
      </Container>

      <Divider />
    </ContainerMobile>
  );
};

export default StoriesList;
