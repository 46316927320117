import { styled } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';

export const Container = styled(ScrollContainer)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  scrollBehavior: 'smooth',
});

export const Divider = styled('div')({
  backgroundColor: 'rgba(217, 217, 217, 0.44)',
  height: '3px',
  width: '100%',
  margin: '26px 0',
});

export const ContainerMobile = styled('div')(({ theme }) => ({
  width: '100vw',
  display: 'none',
  flexDirection: 'column',
  marginTop: '20px',

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
  },
}));

export const Title = styled('h1')({
  fontFamily: 'Barlow',
  fontWeight: 'bold',
  fontSize: '23px',
  margin: '0 0 18px 20px',
});

export const ContainerDesktop = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: '#ffffff',
  padding: '20px 0px 20px 40px',
  margin: '0 0 40px 40px',

  borderRadius: '13px 0 0 13px',
  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.11)',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
