import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonList: React.FC = () => {
  const useStyles = makeStyles({
    root: {
      height: 370,
      minWidth: 204,
      borderRadius: '14px',
      margin: '20px 20px 20px 0px',
      '@media screen and (max-width: 780px)': {
        width: '15vw',
        minWidth: '0',
        height: '15vw',
        maxWidth: '75px',
        maxHeight: '75px',
        flexShrink: 1,
        borderRadius: '100%',
        margin: '0 0 0 13px',
      },
    },
  });
  const { root } = useStyles();
  return (
    <>
      {Array.from({ length: 5 }, (_, key) => key).map(key => (
        <Skeleton className={root} key={key.toString()} variant="rect" />
      ))}
    </>
  );
};

export default SkeletonList;
